import {
    GET_ALL_USER,
    REMOVE_USER,
    IS_SEARCH,
    HANDLE_SEARCH,
    GET_ALL_ACTIVITIES,
    VALUE_SEARCH,
    GET_ALL_ROLES,
    CHANGE_ROLE_NAME,
    GET_ALL_CATEGORIES,
    DELETE_CATEGORY,
    GET_ALL_ARTICLE,
    DELETE_ARTICLE,
    SITE_CATEGORY,
    SITE_ARTICLES,
    COUNT_SEARCH,
    SITE_ADS
} from '../constants'

export function getUsersAct(users) {
    return {
        type: GET_ALL_USER,
        payload: users
    }
}

export function removeUserAct(idUser) {
    return {
        type: REMOVE_USER,
        payload: idUser
    }
}

export function showHideSearchAct(action) {
    return {
        type: IS_SEARCH,
        payload: action
    }
}

export function handleSearchAct(handler) {
    return {
        type: HANDLE_SEARCH,
        payload: handler
    }
}

export function setValueSearch(value) {
    return {
        type: VALUE_SEARCH,
        payload: value
    }
}

export function getActivitiesAct(activities) {
    return {
        type: GET_ALL_ACTIVITIES,
        payload: activities
    }
}

export function getAllRolesAct(roles) {
    return {
        type: GET_ALL_ROLES,
        payload: roles
    }
}

export function changeNameRole(role) {
    return {
        type: CHANGE_ROLE_NAME,
        payload: role
    }
}

/* получить список категорий */

export function getCategoriesAct(categories){
    return{
        type: GET_ALL_CATEGORIES,
        payload: categories
    }
}

/* Удалить категорию */

export function deleteCategoryAct(category){
    return {
        type: DELETE_CATEGORY,
        payload: category
    }
}

/* получить новости */

export function getAllArticlesAct(articles){
    return {
        type: GET_ALL_ARTICLE,
        payload: articles
    }
}

/* удалить новость */
export function removeArticleAct(idArticle){
    return {
        type: DELETE_ARTICLE,
        payload: idArticle
    }
}

export function getAllCategoriesSite(categories){
    return {
        type: SITE_CATEGORY,
        payload: categories
    }
}

// получить новости на сайт

export function getArticlesSite(articles){
    return {
        type: SITE_ARTICLES,
        payload: articles
    }
}

export function getCountSearch(count){
    return {
        type: COUNT_SEARCH,
        payload: count
    }
}

export function getAds(ads){
    return {
        type: SITE_ADS,
        payload: ads
    }
}


